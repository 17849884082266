import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', () => {
    const user = ref<UserDto>({})

    const authenticated = ref(false)

    async function authenticateUser (loginRequest: LoginRequest): Promise<boolean> {
      await $fetch('/api/login?useCookies=true', {
        method: 'post',
        body: loginRequest
      })
      authenticated.value = true
      return authenticated.value
    }

    async function getUserProfile () {
      const data = await $fetch<UserDto>('/api/users/me', {
        method: 'get',
        credentials: 'include'
      })

      if (data) {
        user.value = data
      }
    }

    async function logUserOut () {
      authenticated.value = false
      user.value = {}

      await $fetch('/api/users/signout', {
        method: 'get',
        credentials: 'include'
      })
    }

    return {
      user,
      authenticated,
      authenticateUser,
      getUserProfile,
      logUserOut
    }
  }
)
